.App {
  text-align: center;
}

.content {
  // margin: 30px;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(243, 243, 243);
}


.artists{
  padding: 0px;
}
